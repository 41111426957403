import { computed } from "vue";
import { useStore } from "@/store";
import { currentUser } from "@/services/useAuth";

const locales = computed(() => {
  const store = useStore();
  return store.state.locales;
});

const currentLocale = computed(() =>
  currentUser && currentUser.value ? currentUser.value.locale || defaultLocale.value : defaultLocale.value,
);
const defaultLocale = computed(() => {
  const store = useStore();
  return store.state.account.default_locale;
});

const notifies = computed(() => locales.value["notifies"][currentLocale.value]);
const navbarLocales = computed(() => locales.value["navbar"][currentLocale.value]);

const loginLocales = computed(() => locales.value.login[currentLocale.value]);
const gridLocales = computed(() => locales.value.grid?.[currentLocale.value]);
const techPassportFilterLocales = computed(() => locales.value.tech_passport_filter[currentLocale.value]);
const templateLocales = computed(() => locales.value["tech_passport_template"][currentLocale.value]);
const workflowConstructorLocales = computed(() => locales.value["workflow_constructor"][currentLocale.value]);
const floorPlanLocales = computed(() => locales.value["floor_plan"][currentLocale.value]);
const issueCenterLocales = computed(() => locales.value["situational_center"][currentLocale.value]);
const pprWorkEquipmentLocales = computed(() => locales.value["ppr_work_equipment"][currentLocale.value]);
const confirmModalLocales = computed(() => locales.value["confirm_modal"][currentLocale.value]);
const dynamicIssuesLocales = computed(() => locales.value["dynamic_issues"][currentLocale.value]);
const pprScheduleLocales = computed(() => locales.value["ppr_schedule"][currentLocale.value]);

export {
  currentLocale,
  defaultLocale,
  locales,
  gridLocales,
  loginLocales,
  workflowConstructorLocales,
  techPassportFilterLocales,
  notifies,
  templateLocales,
  floorPlanLocales,
  issueCenterLocales,
  pprWorkEquipmentLocales,
  dynamicIssuesLocales,
  navbarLocales,
  pprScheduleLocales,
};
