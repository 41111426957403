<template lang="pug">
.text-field
  .form-field-label
    span {{ `${label}` }}
    span.form-field-require-tag(v-show="required")
      | *

  .form-field.form-text
    q-input(
      filled,
      no-error-icon,
      v-model="value",
      @input="changeValue",
      :placeholder="placeholder",
      :label-slot="false",
      autocomplete="off",
      type="textarea",
      input-class="text-field-area"
    )
      q-icon.cancel-text-field(name="cancel", v-if="value && !readonly", @click.stop="resetValue")
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  label: { type: String, default: "", required: true },
  required: { type: Boolean, default: false, required: false },
  initialValue: { type: String || null, default: null, required: false },
  placeholder: { type: String, default: "", required: false },
  readonly: { type: Boolean, default: false, required: false },
});

const emit = defineEmits(["change", "reset"]);

const value = ref(props.initialValue);

const changeValue = () => {
  emit("change", { value: value.value });
};

const resetValue = () => {
  value.value = null;
  emit("reset");
  changeValue();
};
</script>

<script>
export default {
  name: "TextField",
};
</script>

<style scoped lang="scss">
.text-field {
  :deep(.text-field-area) {
    padding-right: 20px;
  }
}
</style>
