<template lang="pug">
div
  .form-field-label
    slot(name="label")
      span {{ label }}
    span.form-field-require-tag(v-show="required")
      | *

  //- add preserveSearch to avoid request after ckick outside select
  .form-field.form-multiselect
    multiselect(
      ref="multiselectRef",
      v-model="value",
      track-by="id",
      label="title",
      open-direction="bottom",
      hide-selected,
      :options="options",
      :multiple="multiple",
      :close-on-select="!multiple",
      :show-labels="false",
      :placeholder="placeholder",
      :disabled="readonly",
      @search-change="onSearchChange",
      @select="addItem",
      @remove="removeItem"
    )
      span(slot="noResult")
        .spinner-container.select-field-spinner(v-if="loading")
          q-spinner(color="primary", size="1.7em")
        i(v-else) {{ notifies.no_search_result }}

      span(slot="noOptions")
        i {{ notifies.no_options_list }}

      template(slot="singleLabel", slot-scope="props")
        .option__desc
          .option__title {{ props.option.title }}

      template(slot="option", slot-scope="props")
        .option__title {{ props.option.title }}

      template(slot="afterList")
        div(v-if="options.length && hasNextPage()", style="text-align: center")
          div(v-observe-visibility="reachedEndOfList")
          span(style="padding: 10px") ...

    q-icon.cancel-select-field(name="cancel", v-if="showCancel", @click.stop="resetValue")
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { backend } from "@/api";
import { handleError } from "@/services/handleErrors";
import { notifies } from "@/services/useLocales";
import Multiselect from "vue-multiselect";
import _ from "lodash";

const props = defineProps({
  label: { type: String, default: "", required: false }, // can be blank since label can be also set via slot
  optionsPath: { type: String, default: "", required: false }, // path to fetch options from backend. We add /collection to this url
  fullOptionsPath: { type: String, default: "", required: false }, // path to fetch options from backend from store
  optionsParams: { type: Object, default: () => {}, required: false }, // provide options when fetching options from backend
  required: { type: Boolean, default: false, required: false },
  initialValue: { type: [String, Number, Array], default: null, required: false }, // array is used in selects with multiple: true
  placeholder: { type: String, default: "", required: false },
  readonly: { type: Boolean, default: false, required: false },
  multiple: { type: Boolean, default: false, required: false },
  watch: { type: Object || null, default: null, required: false }, // null or { name: ..., value: ... }
  multiwatch: { type: Array, default: () => [], required: false }, //need for multiwatching
  canPaste: { type: Boolean, default: false, required: false }, // need for search by parsing separated " ,"
  showCancel: { type: Boolean, default: true, required: false }, // if you need hidden reset icon
  options: { type: Array, default: () => [], required: false }, // custom options from front
});

const emit = defineEmits(["change", "reset", "load", "initial-load", "mounted"]);

const perPage = 20;

const loadTimesCount = ref(0);
const loading = ref(false);
const options = ref([]);
const value = ref(null); // matches default prop's initialValue value
const page = ref(1);
const totalCount = ref(-1);
const searchQuery = ref(null);
const timeout = ref(null);
const lastSearchQuery = ref("");
const multiselectRef = ref(null);
const pasting = ref(false);

const canPaste = computed(() => props.canPaste);
const canWatchParent = computed(() => props.watch && props.watch.name);
const canMultiWatch = computed(
  () => props.multiwatch && props.multiwatch.length > 0 && props.multiwatch.find(item => item.name),
);
const showCancel = computed(() => {
  if (!props.showCancel) {
    return false;
  }
  const currentValue = value.value;

  if (props.readonly || !currentValue) {
    return false;
  }

  if (props.multiple) {
    return currentValue.length > 0;
  } else {
    return currentValue !== null;
  }
});
const preparedPropsOptionParams = computed(() => {
  // Option params might be blank
  if (Object.keys(props.optionsParams).length == 0) {
    return {};
  }

  // Build options object
  return Object.fromEntries(
    Object.entries(props.optionsParams).filter(item => {
      const key = item[0];
      const value = item[1];

      // Exclude { "infinite_scroll": {} } as it overrided default behaviour
      if (key == "infinite_scroll" && Object.keys(value).length === 0) {
        return false;
      }

      return true;
    }),
  );
});

const serializedEmitItem = item => {
  return { value: item.id, title: item.title };
};

const serializedEmitList = list => {
  return list.map(item => serializedEmitItem(item));
};

const addItem = item => {
  searchQuery.value = null;

  if (props.multiple) {
    const listWithItem = value.value === null ? [item] : _.unionBy(value.value, [item], "id"); // value is null or list
    const newList = serializedEmitList(listWithItem);
    emit("change", newList);
  } else {
    emit("change", serializedEmitItem(item));
  }
};

const removeItem = item => {
  if (!props.multiple) {
    return;
  }

  const listWithoutItem = value.value.filter(selectedItem => selectedItem.id != item.id);
  const newList = serializedEmitList(listWithoutItem);

  if (newList.length == 0) {
    resetValue();
  } else {
    emit("change", newList);
  }
};

const resetValue = () => {
  value.value = null;
  searchQuery.value = null;
  page.value = 1;
  options.value = [];
  emit("reset");

  loadOptions();

  // Reset values
  if (props.multiple) {
    emit("change", []);
  } else {
    emit("change", { value: null, title: null });
  }
};

// Special syntax is used to be able to connect debounce to a function
const onSearchChange = async args => {
  if (pasting.value) {
    return;
  }
  if (canPaste.value && lastSearchQuery.value.length < args.length - 1) {
    pasting.value = true;
    loading.value = true;
    multiselectRef.value.deactivate();
    await paste(args);
    return;
  }
  lastSearchQuery.value = args;
  options.value = [];
  page.value = 1;

  searchContent(args);
};

const paste = async query => {
  const optionsParams = {
    title: query.split(", "),
    page: 1,
    per_page: perPage,
  };
  const params = { ...optionsParams, ...preparedPropsOptionParams.value };
  const path = props.fullOptionsPath ? props.fullOptionsPath : `/api/v3/${props.optionsPath}`;
  try {
    const { data } = await backend.collection(`${path}/collection`, params);
    value.value = _.unionBy(value.value, data.options, "id");
    emit("change", serializedEmitList(value.value));
  } catch (error) {
    await handleError(error);
  } finally {
    lastSearchQuery.value = "";
    loading.value = false;
    pasting.value = false;
  }
};

const searchContent = async query => {
  searchQuery.value = query.length > 0 ? query : null;

  await loadOptions();
};

const buildDefaultParams = defaultValues => {
  const params = { infinite_scroll: { page: page.value, per_page: perPage }, default_value: defaultValues };

  if (searchQuery.value) {
    params["search_query"] = searchQuery.value;
  }

  if (canWatchParent.value && props.watch.value) {
    params[props.watch.name] = props.watch.value;
  }

  return params;
};

const fetchOptions = async defaultValues => {
  if (props.options.length > 0) {
    options.value = props.options;
    setExistingValue();
    return;
  }
  const defaultParams = buildDefaultParams(defaultValues);
  const params = { ...defaultParams, ...preparedPropsOptionParams.value };

  try {
    const path = props.fullOptionsPath ? props.fullOptionsPath : `/api/v3/${props.optionsPath}`;

    loadTimesCount.value++; // must go before api request
    const response = await backend.collection(`${path}/collection`, params);

    options.value = _.unionBy(options.value, response.data.options, "id");
    totalCount.value = response.data.count;
    page.value += 1;

    setExistingValue();

    emit("load", response.data.options);

    if (loadTimesCount.value == 1) {
      emit("initial-load");
    }
  } catch (error) {
    await handleError(error);
  }
};

const loadOptions = (defaultValues = value.value) => {
  if (timeout.value) clearTimeout(timeout.value);

  loading.value = true;

  timeout.value = setTimeout(async () => {
    await fetchOptions(defaultValues);

    loading.value = false;
  }, 500);
};

// To show existing value (from backend) we need to ensure we have options loaded and then find an object to set
const setExistingValue = () => {
  if (value.value !== null || props.initialValue === null) {
    return;
  }

  if (props.multiple) {
    const selectedItems = options.value.filter(option => {
      const optionsId = option.id.toString();
      const foundOption = props.initialValue.find(selectedItemId => selectedItemId.toString() === optionsId);

      if (foundOption) {
        return true;
      } else {
        return false;
      }
    });

    value.value = selectedItems;
  } else {
    const selectedItem = options.value.find(option => option.id.toString() === props.initialValue.toString());

    if (selectedItem === null || selectedItem === undefined) {
      return;
    }

    value.value = selectedItem;
  }
};

const hasNextPage = () => {
  // Extra check for case if backend would send null or undefined
  if (options.value?.length === 0) {
    return false;
  }

  const lastPage = Math.ceil(totalCount.value / perPage);
  const currentPage = page.value;

  return currentPage <= lastPage;
};

const reachedEndOfList = async reachedEnd => {
  if (reachedEnd && !loading.value) {
    await loadOptions();
  }
};

const watchParent = () => {
  if (canWatchParent.value) {
    watch(
      () => props.watch,
      (newVal, oldVal) => {
        if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
          page.value = 1;
          options.value = [];
          value.value = null;
          searchQuery.value = null;

          if (props.multiple) {
            emit("change", []);
          } else {
            emit("change", { value: null, title: null });
          }
          !props.readonly && loadOptions();
        }
      },
      { deep: true },
    );
  }

  if (canMultiWatch.value) {
    watch(
      () => props.multiwatch,
      (newVal, oldVal) => {
        if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
          page.value = 1;
          options.value = [];
          value.value = null;
          searchQuery.value = null;

          if (props.multiple) {
            emit("change", []);
          } else {
            emit("change", { value: null, title: null });
          }
          !props.readonly && loadOptions();
        }
      },
      { deep: true },
    );
  }
};

onMounted(async () => {
  !props.readonly && (await loadOptions(props.initialValue));

  watchParent();

  emit("mounted", true);
});
</script>

<script>
export default {
  name: "SelectField",
};
</script>

<style lang="scss" scoped>
@import "../../../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";

.select-field-spinner {
  top: 0;
  left: 0;
}
</style>
